<template>
  <div class="bgc-white p-20 bd" id="mainContent">
    <h4 class="c-grey-900" style="display: inline-block;">تعديل سؤال</h4>
    <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
    <button class="btn btn-sm btn-success" @click="showMath">
            <i class="ti-ruler-pencil mL-5" style="color: #fff"></i>إنشاء معادلة
          </button>
    <div class="mT-30">
      <form @submit="checkForm">
        <div class="form-row mT-20">
          <div class="form-group col-md-12">
            <label for="inputEmail4">السؤال</label
            ><textarea class="form-control" id="inputEmail4" v-model="questionText" required ></textarea>
          </div>
        </div>

        <div class="form-row mT-20">
          <div class="form-group col-md-12">
            <label for="inputState">نوع السؤال</label
            ><select id="inputState" class="form-control" v-model="questionTypeId">
            <option selected="selected" value="2">إختر الإجابة الصحيحة</option>
            <option value="1">صح أم خطأ</option>
            </select>
          </div>
        </div>

        <div v-if="questionTypeId == 2">
          <div class="form-row mT-20">
          <div class="form-group col-md-10">
            <label for="inputEmail5">الإجابة الأولي</label
            ><input type="name" class="form-control" id="inputEmail5" v-model="answer1" required />
          </div>
          <div class="form-group col-md-2" style="text-align:center">
              <input class="form-check-input" style="margin-top:35px;width:20px;height:20px" type="radio" v-model="isTrue" name="gridRadios" id="gridRadios1" value="1" >
          </div>
        </div>
        <div class="form-row mT-10">
          <div class="form-group col-md-10">
            <label for="inputEmail6">الإجابة الثانية</label
            ><input type="name" class="form-control" id="inputEmail6" v-model="answer2" required />
          </div>
          <div class="form-group col-md-2" style="text-align:center">
              <input class="form-check-input" style="margin-top:35px;width:20px;height:20px" type="radio" v-model="isTrue" name="gridRadios" id="gridRadios1" value="2">
          </div>
        </div>

        <div class="form-row mT-20">
          <div class="form-group col-md-10">
            <label for="inputEmail7">الإجابة الثالثة</label
            ><input type="name" class="form-control" id="inputEmail7" v-model="answer3" required />
          </div>
          <div class="form-group col-md-2" style="text-align:center">
              <input class="form-check-input" style="margin-top:35px;width:20px;height:20px" type="radio" v-model="isTrue" name="gridRadios" id="gridRadios1" value="3">
          </div>
        </div>

        <div class="form-row mT-20">
          <div class="form-group col-md-10">
            <label for="inputEmail8">الإجابة الرابعة</label
            ><input type="name" class="form-control" id="inputEmail8" v-model="answer4" required />
          </div>
          <div class="form-group col-md-2" style="text-align:center">
              <input class="form-check-input" style="margin-top:35px;width:20px;height:20px" type="radio" v-model="isTrue" name="gridRadios" id="gridRadios1" value="4">
          </div>
        </div>
        </div>

        <div v-else>
          <div class="form-row mT-20">
            <div class="form-group col-md-5" style="text-align:center">
              <label for="">
            <input  type="radio" style="width:20px;height:20px" v-model="answer5" name="gridRadios1" id="gridRadios1" value="صح">صح</label>
            </div>
            <div class="form-group col-md-5" style="text-align:center">
              <label for="">
            <input  type="radio" style="width:20px;height:20px" v-model="answer5" name="gridRadios1" id="gridRadios1" value="خطأ" >خطأ</label>
            </div>
          </div>
        </div>



        <div class="form-row mT-20">
          <loading v-show="loading" />
        <sui-button
          type="submit"
          positive
          class="btn btn-primary mL-10"
          :content="$t('edit')"
          icon="check"
          label-position="left"
          :disabled="loading"
          v-show="!loading"
        />
        <sui-button
          type="button"
          :content="$t('cancel')"
          :disabled="loading"
          v-show="!loading"
          @click="$emit('closePanel', {})"
        />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reload: { type: Function },
    question: {}
  },
  created() {
      this.questionText = this.question.text.body;
      this.questionTypeId = this.question.question_type_id
      if(this.questionTypeId == "1") {
          this.answer5 = this.question.answers[0].answer;
      }
      else {
          this.answer1 = this.question.answers[0].answer;
          this.answer2 = this.question.answers[1].answer;
          this.answer3 = this.question.answers[2].answer;
          this.answer4 = this.question.answers[3].answer;

          console.log(this.question.answers[0].is_true);

          if(this.question.answers[0].is_true == "1") {
              this.isTrue = "1";
          }
          else if(this.question.answers[1].is_true == "1") {
              this.isTrue = "2";
          }

          else if(this.question.answers[2].is_true == "1") {
              this.isTrue = "3";
          }

          else if(this.question.answers[3].is_true == "1") {
              this.isTrue = "4";
          }
      }
  },
  data() {
    return {
      questionText: "",
      questionTypeId: "2",
      isTrue: "1",
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "", 
      answer5: "صح",
      loading: false
    }
  },
  methods: {
    showMath() {
      
      $('#math').modal('show');
      const mf = document.getElementById("formula");

    mf.style.direction = "ltr";
    mf.style.cursor = "auto";
    mf.style.fontSize  = "20px";

    },
    checkForm: function (e) {
      e.preventDefault();
      this.loading = true;
      this.addQuestion();
    },
    addQuestion: function () {
      let headers = {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      };
      this.$http
        .patch(
          "https://yalla-emtihan.com/backend/questions/editQuestion",
          {
            question_id: this.question.id,
            question_type_id : this.questionTypeId,
            question_body: this.questionText.trim(),
            answer1 : this.answer1.trim(),
            answer2: this.answer2.trim(),
            answer3: this.answer3.trim(),
            answer4: this.answer4.trim(),
            answer5: this.answer5.trim(),
            is_ture: this.isTrue
          },
          { headers }
        )

        .then(
          function (data) {
            this.loading = false;
            switch (data.status) {
              case 200:
                this.$emit("closePanel", {});
                this.success(this.$t("success"));
                this.reload();
                break;
            } 
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
            }
          }
        );
    },
    error: function (message) {
      this.$toast.error(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    success: function () {
      this.$toast.success(this.$t("success"), {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>

<style scoped>
#mainContent {
  text-align: right;
  direction: rtl;
}

</style>